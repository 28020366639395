/* eslint-disable */
import React, { Component } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import Grid from '@material-ui/core/Grid'
import WhatDoLeftImage from '../../../images/fq-ask.jpg'

class FAQ extends Component {
  state = {
    open1: true,
    open2: false,
    open3: false,
  }

  handleClick1 = () => {
    this.setState({
      open1: !this.state.open1,
    })
  }

  handleClick2 = () => {
    this.setState({
      open2: !this.state.open2,
    })
  }
  handleClick3 = () => {
    this.setState({
      open3: !this.state.open3,
    })
  }

  render() {
    return (
      <section className='fq-ask-wrap section-padding'>
        <Grid container spacing={3} className='websitePadding'>
          <Grid item xs={12} md={6} sm={12}>
            <Grid className='fq-ask-banner'>
              <img src={WhatDoLeftImage} alt='' />
            </Grid>
          </Grid>
          <Grid item md={6} sm={12}>
            <Grid className='theme-heading'>
              <h2>Frequently Asked Questions</h2>
            </Grid>
            <Grid className='frequent-ask-accrodian'>
              <List
                component='nav'
                aria-labelledby='nested-list-subheader'
                className={'root'}
              >
                <ListItem
                  className='collapse_heading'
                  button
                  onClick={this.handleClick1}
                >
                  <ListItemText primary='Why Do I Need A Home Inspection?' />
                  {this.state.open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open1} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem button className={'nested'}>
                      <ListItemText
                        className='collapse_description'
                        primary={`If you are purchasing the building, you need to consider whether or not repairs are needed now and who’s going to pay for them. Most previously owned houses have unexpected repairs that surface in the first six months of ownership. If you are renting the building, you need to know the damages ahead of time which is different from usual where the tenant gives back the building at the end of a year and realize that you have to do extensive works to repair the damages. A-line has special discounts to people who subscribe for periodical inspections.`}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  className='collapse_heading'
                  button
                  onClick={this.handleClick2}
                >
                  <ListItemText primary='Does it Matter Which Home Inspector I Hire?' />
                  {this.state.open2 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open2} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem button className={'nested'}>
                      <ListItemText
                        className='collapse_description'
                        primary={`Absolutely! Your house will likely be one of the largest investments in your life, it is important to find a knowledgeable and trustworthy inspector to provide an un-biased assessment of the property. You may think an inspector’s job looks simple. In reality, it’s extremely complex. Home inspection requires comprehensive technical knowledge of building systems and practices, which are always evolving. A-line peace of mind is committed to providing YOU with a clear picture of the condition of the major systems in the house.`}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  className='collapse_heading'
                  button
                  onClick={this.handleClick3}
                >
                  <ListItemText primary='Can you repair the technical damages found after inspection?' />
                  {this.state.open3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open3} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem button className={'nested'}>
                      <ListItemText
                        className='collapse_description'
                        primary={`With experience and our professional team, we are able to repair the damages found such as humidity (which goes with plastering and painting), plumbing and electrical repairs; ceiling and roof.`}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </section>
    )
  }
}

export default FAQ
