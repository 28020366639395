import React from 'react'
import OurService1 from '../../../images/services/design.jpeg'
import OurService2 from '../../../images/services/house1paint.jpeg'
import OurService3 from '../../../images/services/inspe1.jpeg'
import OurService4 from '../../../images/services/partition-with-glass.jpeg'
import OurService5 from '../../../images/services/renovation-2.jpeg'
import OurService6 from '../../../images/services/partition1.jpeg'
import OurService7 from '../../../images/services/stair-wood-1.jpeg'
import Grid from '@material-ui/core/Grid'
import ReactReadMoreReadLess from 'react-read-more-read-less'

const ServiceItem = [
    {
        id: 1,
        title: 'Building design',
        image: OurService1,
        description:
      'You can dream the most wonderful house/place, but it requires people to design and make the dream a reality.',
    },
    {
        id: 3,
        title: 'Residential & commercial inspection',
        image: OurService3,
        description:
      'Buying a new home is one of the most exciting times in life and a house is one of the best investments in your life. The last thing you’ll want to experience is the additional worry and stress created by discovering the need for unexpected repairs. Alpha-line home inspectors conduct a thorough inspection of your house at numerous points of evaluation, helping you to fully understand the property’s current condition.',
    },
    {
        id: 4,
        title: 'CNC Air plasma & wood engraving ',
        image: OurService4,
        description:
      'Get your designs implemented with minima error to none.',
    },
    {
        id: 5,
        title: 'Home renovation',
        image: OurService5,
        description:
      'Expect the best when you hire our team of experts for your building painting needs.',
    },
    {
        id: 6,
        title: 'Office partitions',
        image: OurService6,
        description: 'Divide office space quickly and affordably with Alpha Line. ',
    },
    {
        id: 2,
        title: 'Construction',
        image: OurService2,
        description:
      'We focus on the complete satisfaction of our clients. All projects are treated as equally important, no matter their sizes.',
    },
    {
        id: 7,
        title: 'Steel & wood  work',
        image: OurService7,
        description:
      'We have established our reputation as a quality contractor by honoring our commitments, on time and within budget...every time.',
    },
]

function Services() {
    return (
        <section className='what-we-do section-padding'>
            <Grid container spacing={3} className='websitePadding'>
                <Grid item lg={1}></Grid>
                <Grid item lg={10} xs={12} className='text-center'>
                    <Grid className='what-we-do-title'>
                        <span>What we do</span>
                        <h4>
                            We help our clients through various construction services
                            including building design and construction, commercial and
                            residential building inspection, swimming pool inspection, home
                            renovation, and office partitions
                        </h4>
                    </Grid>
                </Grid>
                {ServiceItem.map(service => {
                    return (
                        <Grid key={service.id} item md={3} sm={6} className='text-center'>
                            <Grid className='single-we-do'>
                                <img src={service.image} alt='' />
                                <h3>{service.title}</h3>
                                <ReactReadMoreReadLess
                                    charLimit={175}
                                    readMoreText='Read more ▼'
                                    readLessText='Read less ▲'
                                    readMoreClassName='read-more-less--more'
                                    readLessClassName='read-more-less--less'
                                >
                                    {service.description}
                                </ReactReadMoreReadLess>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </section>
    )
}

export default Services
