import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import FooterCall from '../../../images/call.png'
import FooterHome from '../../../images/home.png'
import FooterLike from '../../../images/like.png'
import Container from '@material-ui/core/Container'
import * as PropTypes from 'prop-types'

const currentYear = new Date()
const year = currentYear.getFullYear()

class Footer extends Component {
    render() {
        return (
            <Grid>
                <section
                    className={
                        this.props.websiteUrl === '/services' ||
            this.props.websiteUrl === '/portfolio'
                            ? 'contact-section text-white contact-sec-bg'
                            : 'contact-section text-white'
                    }
                    id='contact'
                >
                    <Grid
                        container
                        spacing={3}
                        className='contact-info-bg text-white websitePadding'
                    >
                        <Grid item lg={4} sm={4} xs={12} className=''>
                            <Grid className='single-contact-info'>
                                <Grid className='contact-icon'>
                                    <img src={FooterCall} alt='' />
                                </Grid>
                                <Grid className='contact-content'>
                                    <h4>Contacts</h4>
                                    <a href='tel:+250 783458808'>Call Us : +250 783458808</a>
                                    <br />
                                    <a href='tel:+250 786683309'>Other : +250 786683309</a>
                                    <br />
                                    <a href='mailto:alphalinepm@gmail.com'>
                                        Email Us At : alphalinepm@gmail.com
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item lg={4} sm={4} xs={12} className=''>
                            <Grid className='single-contact-info'>
                                <Grid className='contact-icon'>
                                    <img src={FooterHome} alt='' />
                                </Grid>
                                <Grid className='contact-content'>
                                    <h4>Address</h4>
                                    <div>
                                        <span>3 KG 211 Street </span>
                                        <br />
                                        <span>Remera, Kigali</span>
                                        <br />
                                        <span>Rwanda</span>
                                        <br />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={12} className=''>
                            <Grid className='single-contact-info'>
                                <Grid className='contact-icon'>
                                    <img src={FooterLike} alt='' />
                                </Grid>
                                <Grid className='contact-content'>
                                    <h4>Follow Us</h4>
                                    <Grid className='follow-icons'>
                                        <a href='https://www.facebook.com/Alphalinepmltd/'>
                                            <i className='fab fa-facebook-f'></i>
                                        </a>
                                        <a href='https://twitter.com/Alphalinepm'>
                                            <i className='fab fa-twitter'></i>
                                        </a>
                                        <a href='/'>
                                            <i className='fab fa-linkedin-in'></i>
                                        </a>
                                        <a href='skype:Alpha?chat'>
                                            <i className='fab fa-skype'></i>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
                <footer className='footer-section pt-150 pb-80'>
                    <Container>
                        <Grid container spacing={3} className='pb-80 websitePadding'>
                            <Grid item lg={3} sm={4} className='paddingLeft-l'>
                                <Grid className='single-footer-widget'>
                                    <h3>Useful Links</h3>
                                    <ul>
                                        <li>
                                            <a href='/about'>About us</a>
                                        </li>
                                        <li>
                                            <a href='/services'>Services</a>
                                        </li>
                                        <li>
                                            <a href='/portfolio'>Projects</a>
                                        </li>
                                        <li>
                                            <a href='/contact'>Contact</a>
                                        </li>
                                        <li>
                                            <a href='/faq'>FAQ’S</a>
                                        </li>
                                        <li>
                                            <a href='/privacy'>Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href='/disclaimer'>Disclaimer</a>
                                        </li>
                                        <li>
                                            <a href='/terms'>Terms & Conditions</a>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                            <Grid item lg={3} sm={4} className=''>
                                <Grid className='single-footer-widget business-hour'>
                                    <h3>Working hours</h3>
                                    <p>Opining Days :</p>
                                    <span>Monday – Friday : 8AM to 20 PM</span>
                                    <br />
                                    <span>Saturday : 9 AM to 19:00 PM</span>
                                    <br />
                                    <p>Vacations :</p>
                                    <span>All Sunday Days</span>
                                    <br />
                                    <span>All Official Holidays</span>
                                </Grid>
                            </Grid>
                            <Grid item lg={3} sm={4} className=''>
                                <Grid className='single-footer-widget'>
                                    <h3>Our news letter</h3>
                                    <p>
                                        We help our clients through various construction services
                                        including building design, commercial and
                                        residential construction, CNC air plasma & wood engraving services,
                                        home renovation, steel & wood work and office partitions.
                                    </p>
                                    <Grid className='subscribe-box'>
                                        <form action='javascript:'>
                                            <input
                                                type='text'
                                                placeholder='Subscribe In Our Newsletter'
                                            />
                                            <button type='submit'>
                                                <i className='fas fa-angle-right'></i>
                                            </button>
                                        </form>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <Grid className='footer-bootm pt-30'>
                        <Grid container spacing={3} className='websitePadding'>
                            <Grid item lg={6} sm={12} className='footer-text'>
                                <span>Copyright ©{year} aimadev. All Rights Reserved</span>
                            </Grid>
                            <Grid item lg={6} sm={12} className='text-lg-right text-center'>
                                <Grid className='footer-menu'>
                                    <ul>
                                        <li>
                                            <a href='/disclaimer'>Disclaimer</a>
                                        </li>
                                        <li>
                                            <a href='/privacy'>Privacy</a>
                                        </li>
                                        <li>
                                            <a href='/terms'>Terms and conditions</a>
                                        </li>
                                        <li>
                                            <a href='/contact'>Contact us</a>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </footer>
            </Grid>
        )
    }
}

Footer.propTypes = {
    websiteUrl: PropTypes.string,
}

export default Footer
