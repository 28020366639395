import React from 'react'
import Header from '../Home1/Header'
import HeroArea from '../About/HeroArea'
import OurPortfolio from '../OurPortfolio'

const PortfolioPage = () => (
    <div>
        <Header />
        <HeroArea slug='RECENT PROJECT SHOWCASE' title='Our Portfolio' />
        <OurPortfolio />
    </div>
)
export default PortfolioPage
