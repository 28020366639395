import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './component/Home1/Page'
import Footer from './component/Home1/Footer'
import aboutPage from './component/About/aboutPage'
import ContactPage from './component/Contact/ContactPage'
import PortfolioPage from './component/Portfolio/PortfolioPage'
import OurServicePage from './component/OurService/OurServicePage'
import ServiceDetailsPage from './component/SerciceDetails/serviceDetailsPage'
import FAQPage from './component/Home1/FAQ'
import { Terms as TermsPage } from './component/Terms'
import { PrivacyPolicy as PrivacyPolicyPage } from './component/PrivacyPolicy'
import { Disclaimer as DisclaimerPage } from './component/Disclamer'
import SinglePortfolioPage from './component/SinglePortfolio'

// const ServicePath = window.location.href

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            siteUrl: window.location.pathname,
        }
    }

    handleClick() {
        this.setState({
            siteUrl: window.location.pathname,
        })
    }
    render() {
        return (
            <div onClick={this.handleClick.bind(this)} className='App'>
                <div className='main-wrapper'>
                    <Router>
                        <Switch>
                            <Route exact path='/' component={HomePage} />
                            <Route path='/about' component={aboutPage} />
                            <Route path='/contact' component={ContactPage} />
                            <Route path='/faq' component={FAQPage} />
                            <Route path='/portfolio' component={PortfolioPage} />
                            <Route path='/services' component={OurServicePage} />
                            <Route path='/servicesdetails' component={ServiceDetailsPage} />
                            <Route path='/portfoliodetails' component={SinglePortfolioPage} />
                            <Route path='/terms' component={TermsPage} />
                            <Route path='/disclaimer' component={DisclaimerPage} />
                            <Route path='/privacy' component={PrivacyPolicyPage} />
                        </Switch>
                        <Footer websiteUrl={this.state.siteUrl} />
                    </Router>
                </div>
            </div>
        )
    }
}
export default App
