import React from 'react'
import Container from '@material-ui/core/Container'
import Header from '../Home1/Header'

export function Disclaimer() {
    return (
        <>
            <Header />
            <Container>
                <div style={{ margin: 20 }}>
                    <h3>Disclaimer for Alpha Line LTD</h3>

                    <p>
                        If you require any more information or have any questions about our
                        site&apos;s disclaimer, please feel free to contact us by email at
                        fmugisha31@yahoo.com. Our Disclaimer was generated with the help of
                        the{' '}
                        <a href='https://www.disclaimergenerator.net/'>
                            Disclaimer Generator
                        </a>
                        .
                    </p>

                    <h4>Disclaimers for Alphaline.com</h4>

                    <p>
                        All the information on this website -
                        https://alphalinepmltd-mcrauoksy.vercel.app/ - is published in good
                        faith and for general information purpose only. Alphaline.com does
                        not make any warranties about the completeness, reliability and
                        accuracy of this information. Any action you take upon the
                        information you find on this website (Alphaline.com), is strictly at
                        your own risk. Alphaline.com will not be liable for any losses
                        and/or damages in connection with the use of our website.
                    </p>

                    <p>
                        From our website, you can visit other websites by following
                        hyperlinks to such external sites. While we strive to provide only
                        quality links to useful and ethical websites, we have no control
                        over the content and nature of these sites. These links to other
                        websites do not imply a recommendation for all the content found on
                        these sites. Site owners and content may change without notice and
                        may occur before we have the opportunity to remove a link which may
                        have gone bad.
                    </p>

                    <p>
                        Please be also aware that when you leave our website, other sites
                        may have different privacy policies and terms which are beyond our
                        control. Please be sure to check the Privacy Policies of these sites
                        as well as their Terms of Service before engaging in any business or
                        uploading any information. Our Privacy Policy was created by{' '}
                        <a href='https://www.generateprivacypolicy.com/'>
                            the Privacy Policy Generator
                        </a>
                        .
                    </p>

                    <h4>Consent</h4>

                    <p>
                        By using our website, you hereby consent to our disclaimer and agree
                        to its terms.
                    </p>

                    <h4>Update</h4>

                    <p>
                        Should we update, amend or make any changes to this document, those
                        changes will be prominently posted here.
                    </p>
                </div>
            </Container>
        </>
    )
}
