import React from 'react'
import Grid from '@material-ui/core/Grid'

function ContactMap() {
    return (
        <section className='google-map-wrap'>
            <Grid container spacing={3} class='websitePadding'>
                <Grid item lg={12} class=''>
                    <Grid class='gmap'>
                        <iframe
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.5082539985588!2d30.1126261147548!3d-1.9498187985784898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb517d063e9fdd5f!2zMcKwNTYnNTkuNCJTIDMwwrAwNic1My4zIkU!5e0!3m2!1sen!2srw!4v1667320498266!5m2!1sen!2srw'
                            frameBorder='0'
                            allowFullScreen=''
                            aria-hidden='false'
                            tabIndex='0'
                        ></iframe>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}

export default ContactMap
