/* eslint-disable */
import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

import Portfolio1 from '../../images/services/stair-wood-1.jpeg'
import Portfolio2 from '../../images/services/renovation-1.jpeg'
import Portfolio3 from '../../images/services/design.jpeg'
import Portfolio4 from '../../images/services/renovation-2.jpeg'
import Portfolio5 from '../../images/services/painting-pics.jpeg'
import Portfolio6 from '../../images/services/house1paint.jpeg'

const portfolio = [
  {
    id: 1,
    category: 'building design',
    image: Portfolio1,
  },
  {
    id: 2,
    category: 'construction',
    image: Portfolio2,
  },
  {
    id: 3,
    category: 'renovation',
    image: Portfolio3,
  },
  {
    id: 4,
    category: 'building design',
    image: Portfolio4,
  },
  {
    id: 5,
    category: 'renovation',
    image: Portfolio5,
  },
  {
    id: 6,
    category: 'renovation',
    image: Portfolio6,
  },
]

function searchFilter(row, category) {
  return (
    row.category.toLowerCase().includes(category.toLowerCase()) || !category
  )
}

const OurPortfolio = () => {
  const [category, setCategory] = useState('')
  const [isOpen, setOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [viewMore, setViewMore] = useState(false)

  function onOpenHandler(id) {
    setOpen(true)
    setPhotoIndex(id - 1)
  }

  function handleViewMore() {
    return (
      <Grid container spacing={3} className='portfolio-grid-items'>
        {portfolio
          .filter(row => searchFilter(row, category))
          .map(item => (
            <Grid
              key={item.id}
              item
              md={4}
              sm={6}
              className='single-portofolio grid-item d-inline-block building bg-cover text-white'
              style={{ backgroundImage: `url(${item.image})` }}
            >
              <Grid className='portfolio-content'>
                <Grid className='portfolio-cat'>
                  <h3>Working in new case</h3>
                  <p>
                    Excepteur sint occ aecat cupidatat non proident, sunt in
                    culpa qui officia deserunt{' '}
                  </p>
                  <span>{item.category.toUpperCase()}</span>
                </Grid>
                <Link
                  to={`/portfoliodetails/${item.id}`}
                  className='read-more-port'
                >
                  READ MORE <i className='fas fa-arrow-right'></i>
                </Link>
              </Grid>
            </Grid>
          ))}
      </Grid>
    )
  }

  return (
    <Fragment>
      <section
        className='our-portfolio section_bg portfolio-two section-padding'
        id='portfolio'
      >
        <Grid container spacing={3} className='websitePadding'>
          <Grid item xs={12} className='text-center'>
            <Grid className='theme-heading'>
              <span>Working Projects</span>
              <h2>Our Latest Projects</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid className='portfolio-filter-buttons text-center'>
          <button
            className={`cBtn cBtnRadius cBtnUppercase ${
              category === '' ? 'active' : ''
            }`}
            onClick={() => setCategory('')}
          >
            all portfolio
          </button>
          <button
            className={`cBtn cBtnRadius cBtnUppercase ${
              category === 'agency' ? 'active' : ''
            }`}
            onClick={() => setCategory('building design')}
          >
            Building design
          </button>
          <button
            className={`cBtn cBtnRadius cBtnUppercase ${
              category === 'creative' ? 'active' : ''
            }`}
            onClick={() => setCategory('construction')}
          >
            Construction
          </button>
          <button
            className={`cBtn cBtnRadius cBtnUppercase ${
              category === 'design' ? 'active' : ''
            }`}
            onClick={() => setCategory('renovation')}
          >
            Renovation
          </button>
        </Grid>

        {!viewMore && (
          <Grid className='portfolio-grid-items'>
            {portfolio
              .filter(row => searchFilter(row, category))
              .slice(0, 3)
              .map(item => {
                return (
                  <Grid
                    key={item.id}
                    item
                    md={4}
                    sm={6}
                    className='single-portofolio grid-item d-inline-block building bg-cover text-white'
                    style={{ backgroundImage: `url(${item.image})` }}
                  >
                    <Grid className='portfolio-content'>
                      <h3>Working in new case</h3>
                      <p>
                        Excepteur sint occ aecat cupidatat non proident, sunt in
                        culpa qui officia deserunt{' '}
                      </p>
                      <Grid className='portfolio-cat'>
                        <span>{item.category.toUpperCase()}</span>
                      </Grid>
                      <Link
                        to={`/portfoliodetails/${item.id}`}
                        className='read-more-port'
                      >
                        READ MORE <i className='fas fa-arrow-right'></i>
                      </Link>
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
        )}
        {viewMore && handleViewMore()}
        {!viewMore && (
          <Grid className='ajax_load_btn text-center mt-60'>
            <a
              href='javascript:'
              onClick={() => setViewMore(true)}
              className='theme_btn'
            >
              VIEW MORE
            </a>
          </Grid>
        )}
      </section>
    </Fragment>
  )
}
export default OurPortfolio
