import React from 'react'
import Grid from '@material-ui/core/Grid'

function ContactInfo() {
    return (
        <section className='contact-page-wrap section-padding'>
            <Grid container spacing={3} className='contact-deatils websitePadding'>
                <Grid item lg={3} sm={6} className=''>
                    <Grid className='single-contact-details'>
                        <h3>Call Us</h3>
                        <a href='tel:+250783458808'>+250 783458808</a>
                        <br />
                        <a href='tel:+250783458808'>+250 786683309</a>
                    </Grid>
                </Grid>
                <Grid item lg={3} sm={6} className=''>
                    <Grid className='single-contact-details'>
                        <h3>Address</h3>
                        <p>
                            Remera, <br />
                            Kigali - Rwanda
                        </p>
                        <p>
                            3 KG 221 ST
                        </p>
                    </Grid>
                </Grid>
                <Grid item lg={3} sm={6} className=''>
                    <Grid className='single-contact-details contact-icon'>
                        <h3>Chat with us</h3>
                        <p> <i className='fab fa-whatsapp'></i>:<a href='https://wa.me/250783458808?text=Thank%20you%20for%20contacting%20Alphaline!%20%0APlease%20let%20us%20know%20how%20we%20can%20help%20you.' target="_blank" rel="noreferrer">:+250 783458808</a> </p>
                    </Grid>
                </Grid>
                <Grid item lg={3} sm={6} className=''>
                    <Grid className='single-contact-details'>
                        <h3>Email</h3>
                        <a href='mailto:alphalinepm@gmail.com'>alphalinepm@gmail.com</a>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}

export default ContactInfo
