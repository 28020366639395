import React from 'react'
import Header from '../Home1/Header'
import HeroArea from '../About/HeroArea'
import Services from './Services'
import CallToAction from '../Home1/CallToAction'
import OurService from '../Home1/OurService'

function OurServicePage() {
    return (
        <div>
            <Header />
            <HeroArea slug='WHO WE ARE' title='Our Services' />
            <Services />
            <CallToAction />
            <OurService />
        </div>
    )
}
export default OurServicePage
