import React from 'react'
import Header from '../Home1/Header'
import HeroSlider from '../Home1/HeroSlider'
import WhatWeDo from '../Home1/WhatWeDo'
import FAQ from '../Home1/FAQ'
import OurService from '../Home1/OurService'
import CallToAction from '../Home1/CallToAction'
import Portfolio from '../Home1/Portfolio'

function HomePage() {
    return (
        <div>
            <Header />
            <HeroSlider />
            <WhatWeDo />
            <FAQ />
            <OurService />
            <CallToAction />
            <Portfolio />
        </div>
    )
}
export default HomePage
