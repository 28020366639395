import React from 'react'
import Grid from '@material-ui/core/Grid'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SliderImage1 from '../../../images/services/design.jpeg'
import SliderManImage1 from '../../../images/man.png'

function HeroSlider() {
    const settings = {
        dots: false,
        autoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return (
        <Slider {...settings}>
            <section className='hero-slider-area '>
                <Grid className='hero-slides owl-carousel'>
                    <Grid
                        className='single-hero-slide gray-overlay bg-cover bg-top d-flex align-items-center'
                        style={{ backgroundImage: `url(${SliderImage1})` }}
                    >
                        <Grid
                            container
                            spacing={3}
                            className='sLider-grid-item customGridwidth websitePadding'
                        >
                            <Grid item lg={12} xs={12} md={12} className='customGridwidth'>
                                <span>WE MAKE</span>
                                <h1>Dream House</h1>
                                <p>
                                    Whether it is designing and build one or to renovate the
                                    existing building.
                                    <br /> Contact us for quality work to make your dreams come
                                    true.{' '}
                                </p>
                                <a href='/contact' className='theme_btn get_start'>
                                    Get details
                                </a>
                            </Grid>
                        </Grid>
                        <Grid className='slide-img d-none d-lg-block'>
                            <img src={SliderManImage1} alt='' />
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <section className='hero-slider-area'>
                <Grid className='hero-slides owl-carousel'>
                    <Grid
                        className='single-hero-slide gray-overlay bg-cover bg-top d-flex align-items-center'
                        style={{ backgroundImage: `url(${SliderImage1})` }}
                    >
                        <Grid
                            container
                            spacing={3}
                            className='sLider-grid-item websitePadding'
                        >
                            <Grid item lg={12} xs={12} md={12} className='customGridwidth'>
                                <span>WE MAKE</span>
                                <h1>Dream House</h1>
                                <p>
                                    With a skill base and expertise that covers a broad spectrum
                                    of construction services such as Design, Construction,
                                    renovation and home inspection.
                                    <br />
                                    we create spaces that are both unique and personal to our
                                    clients.
                                </p>
                                <a href='/contact' className='theme_btn get_start'>
                                    Get details
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <section className='hero-slider-area'>
                <Grid className='hero-slides owl-carousel'>
                    <Grid
                        className='single-hero-slide gray-overlay bg-cover bg-top d-flex align-items-center'
                        style={{ backgroundImage: `url(${SliderImage1})` }}
                    >
                        <Grid
                            container
                            spacing={3}
                            className='sLider-grid-item websitePadding'
                        >
                            <Grid className='slide-img slide-img-left d-none d-lg-block'>
                                <img src={SliderManImage1} alt='' />
                            </Grid>
                            <Grid item lg={12} xs={12} md={12} className='customGridwidth'>
                                <span>WE MAKE</span>
                                <h1>Dream House</h1>
                                <p>
                                    We offer a wide range of services. <br /> From conception to
                                    completion.
                                </p>
                                <a href='/contact' className='theme_btn get_start'>
                                    Get details
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </Slider>
    )
}

export default HeroSlider
