import React from 'react'
import VideoImage from '../../../images/video_img.jpg'
import Grid from '@material-ui/core/Grid'

function AboutFeatured() {
    return (
        <section className='featured-about-wrap sada-bg section-padding'>
            <Grid container spacing={3} className='websitePadding'>
                <Grid item md={6} sm={12} xs={12} className='col-lg-6 col-md-12'>
                    <Grid className='video-section-content video-section-content-1'>
                        <h2>Why Choose Us</h2>
                        <ul>
                            <li>
                                <h3>Passion:</h3>
                                <p>
                                    Alpha-line is passionate about the work and our strength lies
                                    in working as a team to engage with our clients and deliver
                                    first class customer service and the highest standards of
                                    excellence.
                                </p>
                            </li>
                            <li>
                                <h3>Individualism:</h3>
                                <p>
                                    Options are provided for each design project, understanding
                                    creativity and functionality are specific to each individual
                                    client. Our objective is to provide bespoke solutions, which
                                    fit within specified budgetary restraints.
                                </p>
                            </li>
                            <li>
                                <h3>Honest & Dependable:</h3>
                                <p>
                                    {' '}
                                    Integrity and fairness are the basis of A-line PM. business
                                    ethics, whether dealing with customers, suppliers or fellow
                                    team members. Honesty is not the best policy, but the only
                                    policy. The Owners and Employees of A-line PM LTD are in the
                                    building business because they love it. They are dedicated to
                                    each project and take much pride in the final product.
                                </p>
                            </li>
                            <li>
                                <h3>Quality:</h3>
                                <p>
                                    Alpha-line prides themselves with having a strong dedication
                                    to the principle that a quality product is its own best form
                                    of advertising. Their employees are the best craftsman in the
                                    area. Quality construction continues after the close of the
                                    project.
                                </p>
                            </li>
                        </ul>
                        <a href='/services' className='theme_btn'>
                            LEARN MORE
                        </a>
                    </Grid>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid
                    item
                    md={5}
                    sm={12}
                    xs={12}
                    className='col-lg-5 offset-lg-1 col-md-12'
                >
                    <Grid className='video-section-img'>
                        <img src={VideoImage} alt='' />
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}

export default AboutFeatured
