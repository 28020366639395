import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import OurService1 from '../../../images/services/design.jpeg'
import OurService2 from '../../../images/services/renovation-1.jpeg'
import OurService3 from '../../../images/services/partition1.jpeg'
import OurService4 from '../../../images/services/house3paint.jpeg'

const ServiceItem = [
    {
        id: 1,
        title: 'Building design',
        image: OurService1,
        description:
      'You can dream the most wonderful house/place, but it requires people to design and make the dream a reality.',
    },
    {
        id: 2,
        title: 'Construction',
        image: OurService2,
        description:
      'We focus on the complete satisfaction of our clients. All projects are treated as equally important, no matter their sizes.',
    },
    {
        id: 3,
        title: 'Office partitions',
        image: OurService3,
        description: 'Divide office space quickly and affordably with Alpha Line. ',
    },
    {
        id: 4,
        title: 'Home renovation',
        image: OurService4,
        description:
      'Expect the best when you hire our team of experts for your building painting needs.',
    },
]

function WhatWeDo() {
    return (
        <section
            className='what-we-do section-padding websitePadding'
            id='whatwedo'
        >
            <Grid container spacing={3}>
                <Grid item lg={1} xs={12}></Grid>
                <Grid item lg={10} xs={12} className='offset-lg-1 col-12 text-center'>
                    <Grid className='what-we-do-title'>
                        <span>What we do</span>
                        <h4>
                            We help our clients through various construction services
                            including building design and construction, commercial and{' '}
                            <span>residential building inspection</span>, swimming pool
                            inspection, home renovation, and office partition
                        </h4>
                    </Grid>
                </Grid>
                {ServiceItem.map(service => {
                    return (
                        <Grid key={service.id} item md={3} sm={6} className='text-center'>
                            <Grid className='single-we-do'>
                                <img src={service.image} alt='' />
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid item lg={12} className='what-we-do-button text-center'>
                    <Link to='/services' className='theme_btn'>
                        All Services
                    </Link>
                    <Link to='/contact' className='theme_btn btn_tb'>
                        Contact us now
                    </Link>
                </Grid>
            </Grid>
        </section>
    )
}

export default WhatWeDo
