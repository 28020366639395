import React from 'react'
import Header from '../Home1/Header'
import HeroArea from './HeroArea'
import AboutFeatured from './AboutFeatured'
import FAQ from '../Home1/FAQ'
import Testimonial from '../Testimonial'

function aboutPage() {
    return (
        <div>
            <Header />
            <HeroArea slug='WHO WE ARE' title='About Us' />
            <div style={{ margin: 0, textAlign: 'center', padding: 20 }}>
                <p>
                    Our company exists with a mission—to bring out what&apos;s the best
                    for existing and new building at low cost. True to our tagline, our
                    mission is to reap what&apos;s the best of our clients. And we believe
                    that along with our well-trained team, we can fulfill an advantageous
                    satisfaction of success, not just for our customers, but also to our
                    end.
                </p>
            </div>
            <AboutFeatured />
            <FAQ />
            <Testimonial />
        </div>
    )
}

export default aboutPage
